import Modal, { ModalBody, ModalHeader } from '@atlaskit/modal-dialog';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { AiFillQuestionCircle, AiOutlineClose } from 'react-icons/ai';
import { MdArrowBack, MdRefresh } from 'react-icons/md';
import CustomDataGrid from '~/components/CustomDataGrid';
import Separator from '~/components/Separator';
import { formatCurrencyAsText, moneyFormat } from '~/utils/functions';
import { MdSearch } from 'react-icons/md';
import { FaRegClock } from 'react-icons/fa6';
import { ClipLoader } from 'react-spinners';
import { ThemeContext } from 'styled-components';

import { CardSuperiors } from '../Component/CardSuperiors';
import { Grafico } from '../Component/Grafico';
import { TableExtrato } from '../Component/TableExtrato';
import { useHistoricoProduto } from '../HistoricoProdutoContext';
import {
  ButtonForm,
  ButtonSeparator,
  CloseButton,
  Container,
  ContainerButtons,
  ContainerCardSingle,
  ContainerTable,
  Title,
} from '../styles';
import { columns } from './colunasTabelaPreco';

export const ModalHistoricoProduto: React.FC = () => {
  const {
    isOpenModal,
    setIsOpenModal,
    handleIsOpenExtrato,
    isExtrato,
    produto,
    handleTitleExtrato,
    handleLoadingExtrato,
    handleCardClick,
    handleFormData,
    formData,
    listaPreco,
    loadingListaPreco,
    getHistoricoProduto,
    handleTipoCategoria,
    onCallRequestDetalhado,
    handlePagetExt,
    handleLimitExt,
    setLimitExtrato,
    setEntradaSaida,
    setCallRequestDetalhado,
  } = useHistoricoProduto();

  const { colors } = useContext(ThemeContext);
  const [entradaESaida, setEntradaESaida] = useState<boolean>(false);
  const [labelExtrato, setLabelExtrato] = useState<string>('');
  const handleCloseModal = () => {
    setIsOpenModal(false);
    setEntradaESaida(false);
    handleIsOpenExtrato(false);
    handleFormData({
      ...formData,
      tipo_operacao_entrada: -1,
      dta_ini: moment()
        .subtract(15, 'days')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss[Z]'),
    });
    handleTipoCategoria(15);
    handleTitleExtrato('');
    onCallRequestDetalhado(false);
    setLimitExtrato(0);
    handlePagetExt(1);
    handleLimitExt(10);
  };

  useEffect(() => {
    if (produto.lista_precos) {
      const listaPrecos =
        produto.lista_precos[0] !== null ? produto.lista_precos : [];

      const listaPrecosFormatada = listaPrecos.map((item: any) => ({
        ...item,
        val_preco: formatCurrencyAsText(item.val_preco),
        acoes: <AiFillQuestionCircle size={24} style={{ marginTop: '-2px' }} />,
      }));

      produto.lista_precos = listaPrecosFormatada;
    }
  }, [produto]);

  return (
    <Container>
      {isOpenModal && (
        <Modal
          key="modal"
          width="x-large"
          onClose={() => {
            handleCloseModal();
          }}
          height="56.25rem"
        >
          <div id="modalHeader">
            <ModalHeader
              style={{
                paddingBottom: '0.5rem',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            />
            <ModalBody
              onClick={(e) => e.stopPropagation()}
              style={{ minHeight: '29.375rem' }}
            >
              <ContainerButtons id="ContainerInfoProduto">
                <Title>
                  <h3>
                    {produto.des_produto}{' '}
                    <small
                      style={{
                        fontSize: '0.9375rem',
                        lineHeight: '1.4063rem',
                        color: '#7C7C7C',
                        whiteSpace: 'nowrap',
                      }}
                    >
                      (PLU: {produto.cod_produto})
                    </small>
                  </h3>
                  <h6>{`LOJA ${produto.cod_loja} - ${produto.des_loja} - ${produto.des_cidade}/${produto.des_uf}`}</h6>
                </Title>

                {isExtrato && (
                  <ButtonForm
                    onClick={(e) => {
                      e.stopPropagation();
                      setEntradaESaida(false);
                      handleTitleExtrato('');
                      handleIsOpenExtrato(false);
                      handleLoadingExtrato(false);
                      handleFormData({
                        ...formData,
                        tipo_operacao_entrada: -1,
                        dta_ini: moment()
                          .subtract(15, 'days')
                          .utc()
                          .format('YYYY-MM-DDTHH:mm:ss[Z]'),
                      });
                      handleTipoCategoria(15);
                      setLimitExtrato(0);
                      handlePagetExt(1);
                      handleLimitExt(10);
                    }}
                  >
                    <MdArrowBack /> Voltar
                  </ButtonForm>
                )}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <div>
                    <ButtonForm
                      className="refresh"
                      onClick={(e) => {
                        e.stopPropagation();
                        getHistoricoProduto(
                          produto.cod_produto,
                          produto.cod_loja,
                        );
                      }}
                    >
                      <MdRefresh /> Atualizar
                    </ButtonForm>
                  </div>
                  {!isExtrato && (
                    <div>
                      <CloseButton type="button" onClick={handleCloseModal}>
                        <AiOutlineClose size={24} />
                      </CloseButton>
                    </div>
                  )}
                </div>
              </ContainerButtons>

              {!isExtrato && (
                <div>
                  <ContainerCardSingle>
                    <Row className="containerCards">
                      <Col className="cardSingle" md={3} sm={12}>
                        <CardSuperiors
                          title="estoque"
                          titleContent={produto?.qtd_estoque?.toLocaleString(
                            'pt-BR',
                          )}
                          content="Estoque Atual"
                          footerText="Ir para Extrato"
                          setLabelExtrato={setLabelExtrato}
                        />
                      </Col>
                      <Col className="cardSingle" md={3} sm={12}>
                        <CardSuperiors
                          title="venda"
                          titleContent={
                            produto?.dta_ult_mov_venda || 'Sem Histórico'
                          }
                          content="Última Venda"
                          footerText="Ir para Extrato"
                          setLabelExtrato={setLabelExtrato}
                        />
                      </Col>
                      <Col className="cardSingle" md={3} sm={12}>
                        <CardSuperiors
                          title="lucro"
                          titleContent={produto?.val_lucro_bruto?.toLocaleString(
                            'pt-BR',
                          )}
                          content="Lucro Bruto"
                        />
                      </Col>
                      <Col className="cardSingle" md={3} sm={12}>
                        <CardSuperiors
                          title="media"
                          titleContent={produto?.qtd_venda_media?.toLocaleString(
                            'pt-BR',
                          )}
                          content="Venda Média(dia)"
                        />
                      </Col>
                    </Row>
                  </ContainerCardSingle>

                  <div style={{ marginTop: '10px' }}>
                    <Row
                      style={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        marginTop: '-10px',
                      }}
                    >
                      <Col md={10} sm={12}>
                        <Separator labelText="Custo" childrenWidth="160px" />
                      </Col>
                      <Col md={2} sm={12}>
                        <ButtonSeparator
                          type="button"
                          onClick={(event) => {
                            event.stopPropagation();
                            setEntradaESaida(false);
                            handleIsOpenExtrato(true);
                            setLabelExtrato('Alteração de Custo');
                            handleTitleExtrato('tabelaCusto');
                            handleCardClick('tabelaCusto');
                          }}
                        >
                          <FaRegClock
                            size={16}
                            style={{ marginTop: '-2px', color: 'green' }}
                          />{' '}
                          Histórico
                        </ButtonSeparator>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} sm={12} style={{ overflow: 'auto' }}>
                        <ContainerTable>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Reposição</th>
                                <th>Anterior</th>
                                <th>Nota Fiscal</th>
                                <th>S/ Imp.</th>
                                <th>C/ Imp.</th>
                                <th>Médio</th>
                                <th>PMZ</th>
                                <th>Reajuste</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td align="right">
                                  {moneyFormat(
                                    String(produto.val_custo_rep ?? 0),
                                  )}
                                </td>
                                <td align="right">
                                  {moneyFormat(
                                    String(produto.val_custo_rep_ant ?? 0),
                                  )}
                                </td>
                                <td align="right">
                                  {moneyFormat(
                                    String(produto.val_custo_cheio ?? 0),
                                  )}
                                </td>
                                <td align="right">
                                  {moneyFormat(
                                    String(produto.val_custo_sem_imposto ?? 0),
                                  )}
                                </td>
                                <td align="right">
                                  {moneyFormat(
                                    String(produto.val_custo_com_imposto ?? 0),
                                  )}
                                </td>
                                <td align="right">
                                  {moneyFormat(
                                    String(produto.val_custo_medio ?? 0),
                                  )}
                                </td>
                                <td align="right">
                                  {moneyFormat(
                                    String(produto.val_custo_pmz ?? 0),
                                  )}
                                  {/* <BsLightbulb /> */}
                                </td>
                                <td style={{ width: '130px' }} align="right">
                                  {produto?.dta_ult_alt_custo_rep || '00/00/00'}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </ContainerTable>
                      </Col>
                    </Row>
                  </div>
                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                      marginTop: '-18px',
                    }}
                  >
                    <Col md={10} sm={12}>
                      <Separator
                        labelText="Tabela(s) de Preço(s)"
                        childrenWidth="160px"
                      />
                    </Col>
                    <Col style={{ marginBottom: '8px' }} md={2} sm={12}>
                      <ButtonSeparator
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation();
                          setEntradaESaida(false);
                          handleIsOpenExtrato(true);
                          setLabelExtrato('Alteração de Preço');
                          handleTitleExtrato('tabelaPreco');
                          handleCardClick('tabelaPreco');
                        }}
                      >
                        <FaRegClock
                          size={16}
                          style={{ marginTop: '-2px', color: 'green' }}
                        />{' '}
                        Histórico
                      </ButtonSeparator>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} sm={12}>
                      {loadingListaPreco && (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <ClipLoader color={colors.primary} />
                        </div>
                      )}
                      {!loadingListaPreco && (
                        <CustomDataGrid
                          hasTitle={false}
                          columns={columns}
                          rows={listaPreco}
                          errorMessage="Nenhum preço atribuído ao produto"
                        />
                      )}
                    </Col>
                  </Row>

                  <Row
                    style={{
                      display: 'flex',
                      alignItems: 'flex-end',
                    }}
                  >
                    <Col md={10} sm={12}>
                      <Separator
                        labelText="Entradas e Saídas"
                        childrenWidth="160px"
                      />
                    </Col>
                    <Col style={{ marginBottom: '8px' }} md={2} sm={12}>
                      <ButtonSeparator
                        type="button"
                        onClick={(event) => {
                          event.stopPropagation();
                          setEntradaESaida(true);
                          handleIsOpenExtrato(true);
                          setLabelExtrato('Entradas e Saídas');
                          handleTitleExtrato('entradaSaida');
                          handleCardClick('entradaSaida');
                          setEntradaSaida({
                            pdv_agg: null,
                            fornecedor_agg: null,
                            cliente_agg: null,
                            ajustes_agg: null,
                          });
                          setCallRequestDetalhado(true);
                        }}
                      >
                        <MdSearch
                          size={16}
                          style={{ marginTop: '-2px', color: 'green' }}
                        />{' '}
                        Detalhado
                      </ButtonSeparator>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} sm={12}>
                      <Grafico movimentacoes={produto.lista_movimentos} />
                    </Col>
                  </Row>
                </div>
              )}

              {isExtrato && (
                <div>
                  <TableExtrato
                    label={labelExtrato}
                    entradaESaida={entradaESaida}
                  />
                </div>
              )}
            </ModalBody>
          </div>
        </Modal>
      )}
    </Container>
  );
};
